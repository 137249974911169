import React, { useState } from "react";
import Avatar from "./avatar.png";
import "./Login.css";
import firebase from "../firebaseConfig";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const submit = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        history.push("/home");
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div>
      <div className="wrapper">
        <div className="logo">
          <img src={Avatar} alt="" />
        </div>
        <div className="text-center mt-4 name">
          {" "}
          Welcome kindly login to view{" "}
        </div>
        <form className="p-3 mt-3" onSubmit={(e) => submit(e)}>
          <div className="form-field d-flex align-items-center">
            <span className="far fa-user"></span>
            <input
              type="text"
              name="Email id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="userName"
              placeholder="Email id"
            />
          </div>
          <div className="form-field d-flex align-items-center">
            <span className="fas fa-key"></span>
            <input
              type="password"
              name="password"
              id="pwd"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <button className="btn mt-3" type="submit">
            Login
          </button>
        </form>
        {/* <div className="text-center fs-6">
          
          <a href="/">Forget password?</a> or <a href="/">Sign up</a>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
