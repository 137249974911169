import React from "react";
import './Gallery.css'
import Photo1 from './img/image1.jpg'
import Photo2 from './img/image2.jpg'
import Photo3 from './img/image3.jpg'
import Photo4 from './img/image4.jpg'
import Photo5 from './img/image5.jpg'
import Photo6 from './img/image6.jpg'
import Photo7 from './img/Images (1).jpg'
import Photo8 from './img/Images (2).jpg'
import Photo9 from './img/Images (3).jpg'
import Photo10 from './img/Images (4).jpg'
// import Photo11 from './img/Images (5).jpg'
import Photo12 from './img/Images (6).jpg'
// import Photo13 from './img/Images (7).jpg'
import Photo14 from './img/Images (8).jpg'
import Photo15 from './img/Images (9).jpg'
// import Photo16 from './img/Images (10).jpg'
// import Photo17 from './img/Images (11).jpg'
import Photo18 from './img/Images (12).jpg'
import Photo19 from './img/Images (13).jpg'
// import Photo20 from './img/Images (14).jpg'
import Photo21 from './img/Images (15).jpg'
import Photo22 from './img/Images (16).jpg'
// import Photo8 from './img/image8.jpg'

export default function Gallery() {
  return (
    <div>
       <div className="text-center mt-4 p-4 name">
          Welcome Afsha
        </div>
      <section className="gallery min-vh-100">
        <div className="container-lg">
          <div className="row gy-4 row-cols-1 row-cols-sm-2 row-cols-md-3">
            <div className="col">
              <img src={Photo1} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo2} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo3} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo4} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo5} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo6} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo7} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo8} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo9} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo10} className="gallery-item" alt="gallery" />
            </div>
            {/* <div className="col">
              <img src={Photo11} className="gallery-item" alt="gallery" />
            </div> */}
            <div className="col">
              <img src={Photo12} className="gallery-item" alt="gallery" />
            </div>
            {/* <div className="col">
              <img src={Photo13} className="gallery-item" alt="gallery" />
            </div> */}
            <div className="col">
              <img src={Photo14} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo15} className="gallery-item" alt="gallery" />
            </div>
            {/* <div className="col">
              <img src={Photo16} className="gallery-item" alt="gallery" />
            </div> */}
            {/* <div className="col">
              <img src={Photo17} className="gallery-item" alt="gallery" />
            </div> */}
            <div className="col">
              <img src={Photo18} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo19} className="gallery-item" alt="gallery" />
            </div>
            {/* <div className="col">
              <img src={Photo20} className="gallery-item" alt="gallery" />
            </div> */}
            <div className="col">
              <img src={Photo21} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo22} className="gallery-item" alt="gallery" />
            </div>
            {/* <div className="col">
              <img src={Photo} className="gallery-item" alt="gallery" />
            </div>
            <div className="col">
              <img src={Photo} className="gallery-item" alt="gallery" />
            </div> */}

          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="gallery-modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img src="img/image1.jpg" className="modal-img" alt="modal img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
