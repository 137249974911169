import React from "react";
import "./Home.css";
import firebase from "../firebaseConfig";
import { useHistory } from "react-router-dom";
import Gallery from "./Gallery";

export default function Home() {
  const history = useHistory();

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <div>
      <div className="App">
        <header className="App-header">
        <button onClick={() => signout()} className="buttonD">
            <span className="fas fa-sign-out-alt text-white"> Log Out</span>
          </button>
          <Gallery />
        </header>
      </div>
    </div>
  );
}
