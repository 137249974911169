import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import firebase from "./firebaseConfig";
import Home from './components/Home'
import Login from './components/Login'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user?.email === "afshaparween2020@gmail.com" ) {
          setUser(true);
        } else {
          setUser(false);
        }
      })

  }, [user]);
  if (user) {
    return (
      <Provider store={store}>
        <BrowserRouter>
                <React.Suspense>
                  <Switch>
                    <Route
                      path="/home"
                      name="Homepage"
                       component={Home}
                    />
                  </Switch>
                </React.Suspense>    
        </BrowserRouter>
      </Provider>
    );
  } else {
    return (
      <BrowserRouter>
        <React.Suspense>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              component={Login}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }

};

export default App;
