import {initializeApp} from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBVY77Kf44zGamK1gfBNtDGTpK3Pe0MoZY",
    authDomain: "photo-af.firebaseapp.com",
    projectId: "photo-af",
    storageBucket: "photo-af.appspot.com",
    messagingSenderId: "213401592412",
    appId: "1:213401592412:web:16f23d0630cc7125783c99",
    measurementId: "G-8T2147SBEN"
};
 

const app = initializeApp(firebaseConfig)
export default app